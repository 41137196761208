.simulation-container {
  padding-bottom: 40px;
  border-bottom: 1px solid #d2d6d6;
  max-width: 100%;
  margin-bottom: 30px;
}

.simulation-container-image {
  height: 500px !important;
  max-height: 800px!important;
  object-fit: contain;
}

.simulation-container-other-images {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.simulation-container-other-images>div {
  flex: 0 0 auto;
  height: 90px;
  width: 140px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media screen and (orientation:portrait) and (max-width:768px) {
  .simulation-container> :not(:first-child) {
    padding: 0px 15px 0px 15px;
  }
  .simulation-container h1 {
    position:relative !important;
    top:0px !important;
  }

  .height-div {
    display: none;
  }
  .simulation-container {
    border-bottom: 0px;
  }
}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {


  .simulation-container {
    border-bottom: 0px;
    margin: 0 auto;
  }
  

}