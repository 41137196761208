.setup-stats {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.setup-stats>h5 {
  margin: 0 30px 0 7px;
}


.dropdown-share-content {
  position: absolute;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 10px;
  padding: 10px 0px 20px 0px;
  margin: 0 auto;
}

.dropdown-share-content h4 {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold !important;
}

.dropdown-product-icons {
  display: flex;
  align-self: center;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;

}



.setup-dates {
  margin-bottom: 20px;
}

.setup-dates>h4:first-child {
  opacity: 0.5;
}

.setup-hashtag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 107px;
}

.setup-hashtag>h4 {
  margin-right: 15px;
}

.setup-related {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (orientation:portrait) and (max-width:768px) {
  .setup-hashtag {
    padding-bottom: 40px;
    border-bottom: 1px solid #d2d6d6;
    margin-bottom: 30px;
  }

  .setup-container h1 {
    padding: 0px 18px 15px 18px !important;

  }
  .dropdown-share-content {
    margin: 0px 15px 0px 15px;
    left:0;
    right:0;
  }
}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

  .setup-hashtag {
    padding-bottom: 40px;
    border-bottom: 1px solid #d2d6d6;
    margin-bottom: 30px;
  }

  .setup-container h1 {
    padding: 0px 18px 15px 18px !important;
  }

  .dropdown-share-content {
    margin: 0px 15px 0px 15px;
    left:0;
    right:0;
  }
}