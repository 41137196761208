@font-face {
  font-family: OpenSansLight;
  src:  url(OpenSans-Light.ttf);
}

@font-face {
    font-family: OpenSansRegular;
    src:  url(OpenSans-Regular.ttf);
  }

@font-face {
    font-family: OpenSansBold;
    src:  url(OpenSans-Bold.ttf) ;
  }


.lightText{
  font-family: OpenSansLight !important;
}

.regularText{
  font-family: OpenSansRegular !important;
}

.boldText{
  font-family: OpenSansBold !important;
}


/* exSmall text */
.regularExSmall{
  font-family: OpenSansRegular;
  font-size: var(--exSmall);
}
.boldExSmall{
  font-family: OpenSansBold;
  font-size: var(--small);
}
/* exSmall text end */


/* small text */
.regularSmall{
  font-family: OpenSansRegular;
  font-size: var(--small);
}

.boldSmall{
  font-family: OpenSansBold;
  font-size: var(--small);
}
/* small text end */

