:root {
  --black: #000000;
  --white: #ffffff;
  --gray: #f5f5f5;
  --gray2: #dfe3e3;
  --darkGray: #c9d2d2;
  --blue: #23a7de;
  --darkBlue: #3372df;
  --darkerBlue: #4e34c9;
  --darkGreen: #19cb59;
  --green: #9fea47;
  --orange: #f9c221;
  --red: #fb536a;
  --purple: #c138d0;
  --black2: #232121;
}

.blackText {
  color: var(--black) !important;
}

.whiteText {
  color: var(--white) !important;
}

.grayText {
  color: var(--gray) !important;
}

.blueText {
  color: var(--blue) !important;
}
