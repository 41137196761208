.data-card {
  border-radius: 4px;
  background-color: var(--white);
  padding: 9px 10.29px 9px 10.29px;
  cursor: pointer;

  width: 312px;
  box-shadow: none;
  transition: all 0.5s ease;
}

.data-card1 {
  margin:150px;
  border-radius: 4px;
  background-color: var(--white);
  padding: 15px 25px 15px 25px;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.5s ease;
}

.data-card:hover {
  border-radius: 10px;
  box-shadow: 0px 0px 10px #00000029;
  transition: all 0.5s ease;
}

.card-image {
  height: 160px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid var(--gray2);
}

.card-image>img {
  object-fit: contain;
  aspect-ratio: auto;
}

.card-name {
  margin: 15px 0 11px 0;
}

.card-name>h4 {
  line-height: 22px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-info>div:first-child {
  display: flex;
  align-items: center;
}

.card-info>div:first-child>.rs-avatar.blue {
  background-color: var(--blue);
}

.card-info>div:first-child>.rs-avatar.darkBlue {
  background-color: var(--darkBlue);
}

.card-info>div:first-child>.rs-avatar.darkerBlue {
  background-color: var(--darkerBlue);
}

.card-info>div:first-child>.rs-avatar.darkGreen {
  background-color: var(--darkGreen);
}

.card-info>div:first-child>.rs-avatar.green {
  background-color: var(--green);
}

.card-info>div:first-child>.rs-avatar.orange {
  background-color: var(--orange);
}

.card-info>div:first-child>.rs-avatar.red {
  background-color: var(--red);
}

.card-info>div:first-child>.rs-avatar.purple {
  background-color: var(--purple);
}

.card-info>div:first-child>h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  min-width: 70px;
}

.card-info>div:first-child>h5:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
  line-height: 20px;
}

.card-info>div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-info>div:last-child>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


@media screen and (orientation:portrait) and (max-width: 845px) {
  .data-card {
    margin: 0 auto;
  }
}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

.data-card {
    margin: 0 auto;
  }
}