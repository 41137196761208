@import './data-container.css';
@import './actions.css';
@import './stats.css';
@import './simulation-container.css';
@import './comments.css';

.setup-container{
    max-width: 1400px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 100px;
}