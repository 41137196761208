.filters {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 4px;
  border: 1px solid var(--darkGray);
  overflow: hidden;
  height: fit-content;
  position: sticky;
  top: 0;
}

.filters > div:first-child {
  padding: 23px 15px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--darkGray);
}

.filters > div:first-child > h4 {
  margin-left: 14px;
}

.tab {
  padding: 12.5px 0;
  background-color: var(--gray);
  cursor: pointer;
  border-bottom: 1px solid var(--darkGray);
}

.tab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  padding: 0 15px;
}
.tab-header > img {
  transform: rotateZ(180deg);
}

.tab-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 7px 8px 7px;
  padding: 8px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.tab-option.active {
  background-color: var(--white);
  border: 1px solid var(--gray2);
  border-radius: 4px;
  transition: all 0.5s ease;
}

.tab-option:last-child {
  margin-bottom: 0;
}

.tab-option:not(.active):hover {
  background-color: var(--gray2);
  border-radius: 4px;
}

.tab-option > div {
  display: flex;
  align-items: center;
}

.tab-option > div > img {
  margin-right: 11px;
}

.filters > div:last-child {
  border-bottom: none;
}
