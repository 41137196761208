@import "./data-card.css";
@import "./pagination.css";

.container {
  padding: 10px 20px 100px 20px;
  /* display: grid; */
  grid-template-columns: 1fr;
  grid-template-rows: 120px auto;
  width: 100%;
  column-gap: 20px;
  overflow: auto;
  top: 80px;
  bottom: 150px;
  left: 0;
  right: 0;

}

.iconsContainer {
  display: flex;
  margin: 0 auto;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
}

.iconsContainer .icons {
  position: relative;
  text-align: center;
  height: 50px;
  top: 15px;
  left: 15px;
  padding: 0px 16px 0px 16px;
}

.iconsContainer .icons img {
  height: 16px;
  width: 16px;
}

.filter {
  display: none;
}

.data-container {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  /* grid-template-columns: repeat(4, 300px); */
  gap: 20px;
  /* border:1px solid; */
  width: 70vw;
  justify-content: flex-start;
  position: relative;
  margin: 0 auto;
}

#user-header-info {
  position: relative;
}

#user-header-info:hover .user-dropdown {
  display: block;
}

.user-dropdown {
  display: none;
  min-width: 150px;
  width: 100%;
  position: absolute;
  padding: 9px 7px;
  bottom: -105px;
  right: -20px;
  z-index: 9999;
  background:#2E3333 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 4px;
}

.user-dropdown>div {
  padding: 10px 13px;
  cursor: pointer;
}

/* .user-dropdown>div:hover {
  background:#2E3333 0% 0% no-repeat padding-box;
  border-radius: 4px;
} */

.user-dropdown>div>h1 {
  line-height: 22px;
}

.no-setups {
  grid-column-start: 1;
  grid-column-end: span 4;
  font-size: 32px;
  font-family: 'OpenSansRegular' !important;
  font-weight: normal !important;
  /* width: 100%; */
  margin: 0 auto;
  align-self: center;
  text-align: center;
}

.filters-icons {
  display: flex;

}

@media screen and (orientation:portrait) and (max-width: 845px) {
  .icons {
    left: 0px !important;
    margin-bottom: 30px;
  }

  .filter {
    display: flex;
    width: 60px;
    height: 60px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 4px;
    opacity: 1;
  }

  .filter img {
    margin: auto;
    display: block;
  }


  .filters-icons {
    width: 100%;
    justify-content: space-evenly;
  }

  .iconsContainer {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .data-container {
    width: 100%;
  }

  .container {
    padding: 10px 0px 100px 0px;
  }
}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

  .icons {
    left: 0px !important;
    margin-bottom: 30px;
  }

  .filter {
    display: flex;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 4px;
    opacity: 1;
  }

  .filter img {
    margin: auto;
    display: block;
  }


  .filters-icons {
    width: 50%;
    justify-content: space-evenly;
  }

  .iconsContainer {
    flex-wrap: wrap;
    width: auto;
    /* justify-content: space-evenly; */
  }

  .data-container {
    width: 100%;
  }
}