@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.body_error {
    min-height: 60vh;
    background: grey;
    background-size: 400%;
}

.error-page-logo {
    width: 40vw;
    margin-bottom: 2rem;
}

#error-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

#error-page .content_error {
    max-width: 600px;
    text-align: center;
}

.content_error h2.header_error {
    font-size: 3vw!important;
    line-height: 1em!important;
    position: relative!important;
}

.content_error h2.header_error:after {
    position: absolute;
    content: attr(data-text);
    top: 0;
    left: 0;
    right: 0;
    background-size: 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
}


.content_error h4 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #000;
    font-size: 1.5vw!important;
    max-width: 600px;
    position: relative;
}

.content_error h4:after {
    position: absolute;
    content: attr(data-text);
    top: 0;
    left: 0;
    right: 0;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content_error p {
    font-size: 1.2em!important;
    color: #0d0d0d;
}

.content_error .btns {
    margin: 25px 0;
    display: inline-flex;
}

.content_error .btns a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
    border: 2px solid #69a6ce;
    color: #69a6ce;
    font-weight: 500!important;
    padding: 10px 25px;
    border-radius: 25px;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

.content_error .btns a:hover {
    background: #69a6ce;
    color: #fff;
}

.content_error {
    display: flex;
    flex-direction: column;
    align-items: center;
}