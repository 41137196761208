@import './header.css';
@import './footer.css';

.greenHeader {
    width: 100%;
    background-color: #CFF4A3;
    height: 48px;
}

.greenHeader p {
    align-self: center;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
}

.greenHeader img {
    align-self: center;
    margin-right:15px
}
