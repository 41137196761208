:root {
    --exSmall: 14px;
    --small: 16px;
    --medium: 18px;
    --large: 24px;
    --exLarge:30px;
  }

  .exSmallText{
    font-size: var(--exSmall) !important;
  }

  .smallText{
    font-size: var(--small) !important;
  }

  .mediumText{
    font-size: var(--medium) !important;
  }

  .largeText{
    font-size: var(--large) !important;
  }

  .exLargeText{
    font-size: var(--exLarge) !important;
  }