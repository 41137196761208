.field-input-container {
  grid-column: 1 / span 2;
  display: flex;
  /* width: 54%; */
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}

.field-input-categories {
  display: flex;
  align-items: center;
  background-color: var(--gray);
  cursor: pointer;
}

.search-placeholder::placeholder {
  color: var(--black) !important;
  font-family: OpenSansRegular !important;
  font-size: 16px;
}
