@import "./fonts/font-faces.css";
@import "./fonts/fonts-sizes.css";
@import "colors.css";

@import "./components/index.css";

body {
  margin: 0;
  font-family: OpenSansRegular, OpenSansLight, OpenSansBold, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black) !important;
  background-color: var(--gray) !important;
  scroll-behavior: smooth;
  overflow: auto;
}

h1 {
  font-size: var(--exLarge) !important;
  font-family: OpenSansBold !important;
  font-weight: bold !important;
}

h2 {
  font-size: var(--large) !important;
  font-family: OpenSansBold !important;
  font-weight: bold !important;
}

h3 {
  font-size: var(--medium) !important;
  font-family: OpenSansBold !important;
  font-weight: bold !important;
}

h4 {
  font-size: var(--small) !important;
  font-family: OpenSansRegular !important;
  font-weight: normal !important;
}

h5 {
  font-size: var(--exSmall) !important;
  font-family: OpenSansRegular !important;
  font-weight: normal !important;
}

h4.bold,
h5.bold {
  font-family: OpenSansBold !important;
  font-weight: bold !important;
}

/* rs styles */
.rs-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* rs styles end */
.yarl__container{
  position: sticky;
  max-width: 50%;
  max-height: 50%;
  transform: translate(50%, 50%);
}

/* Ensure the content (image/video) fits within the lightbox */
/* setting to remove an error screen during development */ 
iframe
{
    display: none;
}