.setup-comment {
  display: grid;
  grid-template-columns: 0.7fr 9.3fr;
  column-gap: 13px;
  margin-top: 30px;
}

.textarea-custom:focus {
  outline: none;
}

.setup-comment.reply {
  margin-top: 42px;
}

.setup-comment-info {
  display: flex;
  align-items: center;
}

.setup-comment-info > h4:first-child {
  line-height: 22px;
}

.setup-comment-info > h4:last-child {
  opacity: 0.5;
  margin-left: 12px;
  line-height: 22px;
}
