.setup-login {
    background-color: var(--blue);
    padding: 13px 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}
.anlyses-img-btn{
    background-color: var(--blue);
    padding: 6px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    color: white;
    font-size: 14px !important;
    font-weight: bold;
    margin-top: 20px;
}
.setup-images-preview_left{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}
.loader-setup-iframe {
    position: absolute;
    max-width: 1024px;
    width: 100%;
    height: inherit;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    top: 360px;
    background-color: rgba(255, 255, 255, 0.7);
}

.setup-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
}

.spinner {
    position: absolute;
    margin: 0 auto;
    justify-content: center;
    align-self: center;
}

.setup-actions>div {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0;
    border-radius: 4px;
    cursor: pointer;
}

.setup-actions>div.fav>h4 {
    opacity: 0.5;
}

.setup-actions>div>img {
    margin-right: 12px;
}

@media screen and (orientation:portrait) and (max-width:768px) {

    .comments {
        padding-bottom: 40px;
        border-bottom: 1px solid #d2d6d6;
        margin-bottom: 30px;
    }

    .second-info {
        margin: 0 auto;
        padding: 0px 15px 0px 15px

    }
    .setup-login h4 {
        font-size: 18px !important;
        font-weight: bold;
    }
  
  }


  @media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

    .comments {
        padding-bottom: 40px;
        border-bottom: 1px solid #d2d6d6;
        margin-bottom: 30px;
    }

    .second-info {
        margin: 0px 15px 0px 15px

    }
    .setup-login h4 {
        font-size: 18px !important;
        font-weight: bold;
    }

  }