.setup-data-container{
    margin-top: 40px;
    display: grid;
    
    grid-template-columns: 9fr 3fr;
    gap: 40px;   
}

@media screen and (orientation:portrait) and (max-width: 768px) {
    .setup-data-container{
        margin-top: 15px;

        display: block;
        flex-direction: column;
    }

}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

    .setup-data-container{
        margin-top: 15px;

        display: block;
        flex-direction: column;
    }
}