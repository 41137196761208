.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #FFFFFF;
  box-shadow: none;
  transition: all 0.2s ease;
  background-color: #2E3333;
  /*margin: 0 0 500px 0;*/
  height: 130px;
  padding: 0!important;
}

.header.white {
  background-color: var(--white);
  box-shadow: 0px 0px 20px #00000029;
  transition: all 0.2s ease;
}

.part1,
.part2 {
  display: flex;
  align-items: center;
  position: relative;
}


.part1>div {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.5s ease;
}

.part1>div:first-child {
  margin-right: 6vW;
}

.part1>div:not(:first-child) {
  margin-left: 1vw;
}

.part1>div:not(:first-child)>img {
  filter: none;
  transition: filter 0.5s ease;
}

/* .part1>div:not(:first-child):hover>img {
  filter: invert(58%) sepia(40%) saturate(886%) hue-rotate(154deg) brightness(89%) contrast(94%);
  transition: filter 0.5s ease;
} */

.title-menu {
  padding: 10px;

}

.title-menu span {
  font-family: 'OpenSansRegular' !important;
  font-weight: 500;
}

.title-menu:hover {
  border-radius: 5px;
  transition: all 0.5s ease;
  background-color: rgba(255,255,255,0.2);
}

.part2 {
  margin-left: 50px;
  justify-content: flex-end;
}

.part2>button {
  height: 42px;
  border-radius: 36px;
  width: 135px;
  padding: 9px 0;
  font-size: 16px !important;
  font-family: 'OpenSansRegular' !important;
  font-weight: 600;
  justify-content: center;
  transition: all 0.5s ease;
  color: var(--blue);
}

.part2>button:first-child {
  height: 42px;
  font-size: 16px !important;
  width: 135px;
  font-weight: 600;
  padding: 9px 0;
  font-family: 'OpenSansRegular' !important;
  justify-content: center;
  border-radius: 36px;
  transition: all 0.5s ease;
  color: var(--#23A7DE);
}

.part2>button:hover {
  background-color: black !important;
  color: white;
  transition: all 0.5s ease;
}

.part2>button:nth-child(2) {
  background-color: var(--blue);
  margin-left: 20px;
}


.logged-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  margin-left: 16px;

}

.logged-button>h4 {
  margin: 0 12px;
  color:white
}

.logged-button:first-child {
  margin-right: 65px;
}

.arrow-space {
  margin-left: 7px;
}

.dropdown-product-content {
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  top:93px;
  position: absolute;
  width: 366px;
  background: #2E3333;
  border-radius: 4px;

}




.dropdown-product {
  align-items: center;
  display: flex;
}

.dropdown-product.top-product {
  padding:10px;
  border-radius: 5px;
}

.dropdown-product.top-product img {
  margin-right: 5px;
}

.dropdown-product.top-product:hover {
  transition: all 0.5s ease;
  background-color: rgba(255,255,255,0.2);
}

.dropdown-product-title {
  font-size: 16px;
  font-weight: 600;
}

.dropdown-product-description {
  font-size: 16px;
  font-weight: 300;
}

.dropdown-solutions-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  top:93px;
  position: absolute;
  width: 300px;
  background: #2E3333;
  border-radius: 4px;
}


.dropdown-solutions-title {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
}

.dropdown-solutions-description {
  font-size: 14px;
  font-weight: 400;
}

.dropdown-resources-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px;
  top:93px;
  position: absolute;
  width: 300px;
  height: 300px;
  background: #2E3333;
  border-radius: 4px;
}

.dropdown-resources-column {
  padding: 10px;
}

.dropdown-resources-column.right {
  padding-left: 30px;
  padding-right: 30px;
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #dfe3e3;
  min-height: 380px;
  display: flex;
}

.dropdown-resources-title {
  font-size: 16px;
  font-weight: 600!important;
}

.dropdown-resources-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
}


.dropdown-resources-list-item>p {
  font-size: 16px;
  font-weight: normal;
}

.resources-slider {
  width: 458px;
  height: 240px !important;
  margin-left: 80px;
}

.resources-slider-left {
  position: absolute;
  top: 45%;
  left: 90px;
  z-index: 30;
}

.resources-slider-left>img {
  transform: rotate(90deg);
  width: 25px;
  height: 25px;
}

.resources-slider-right {
  position: absolute;
  top: 45%;
  right: 10px;
  z-index: 30;
}

.resources-slider-right>img {
  transform: rotate(-90deg);
  width: 25px;
  height: 25px;

}

.part3 {
  display: none;
}

@media (max-width:1180px) {
  .part1 h4 {
    font-size: 1.3vw !important;
  }

  .part2>button {
    height: 40px;
    font-size: 13px !important;
    width: 90px;
    padding: 9px 0;
    border-radius: 4px;
    justify-content: center;
    transition: all 0.5s ease;
    border: 1px solid var(--blue);
    color: var(--blue);
  }

  .part2>button:first-child {
    height: 40px;
    font-size: 13px !important;
    width: 90px;
    padding: 9px 0;
    border-radius: 36px;
    justify-content: center;
    transition: all 0.5s ease;
    border: 1px solid var(--black);
    color: var(--black);
  }

  .logged-button:first-child {
    margin-right: 5px;
  }

  .part2 .logged-button {
    height: 40px;
    transition: all 0.5s ease;
  }

  .logged-button>h4 {
    margin: 0 5px;
  }

  .part2 .logged-button h4 {
    font-size: 13px !important;

  }


}

@media screen and (orientation:portrait) and (max-width: 768px) {

  .part3 {
    display: block;
  }

  .hamburger {
    width: 25px;
    margin-top: 5px;
    background-color: white;
    height: 3px;
  }

  .part1>div:not(:first-child),
  .part2 {
    display: none !important;
  }

  .menu-container {
    margin: 0 auto;
    top: 70px;
    font-size: 20px;
    background-color: white;
    right: 0px;
    box-shadow: 0px 0px 10px #00000029;
    position: absolute;

  }
  .dialog {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top:50%;
    height: 435px;
    width: calc(100vw - 55px);
    border-radius: 8px;
    box-shadow: 0 4px 25px -1px rgba(109, 111, 124, 0.5);
    background-color: #fff;
  }

  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
  }

}

@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {



  .part3 {
    display: block;
  }

  .hamburger {
    width: 25px;
    margin-top: 5px;
    background-color: black;
    height: 3px;
  }

  .part1>div:not(:first-child),
  .part2 {
    display: none !important;
  }

  .menu-container {
    margin: 0 auto;
    top: 70px;
    font-size: 20px;
    background-color: white;
    right: 0px;
    box-shadow: 0px 0px 10px #00000029;
    position: absolute;

  }

}
