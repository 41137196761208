.pagination {
  grid-column: 1 / span 2;
  display: flex;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: var(--gray2);
  border-radius: 50px;
  margin-right: 15px;
}

.pagination-button.active {
  background-color: var(--black);
  color: white;
}

.pagination-button.disable {
  opacity: 0.5;
}

.pagination-button.prev > img {
  transform: rotateZ(90deg);
}

.pagination-button.prev-prev > img {
  transform: rotateZ(180deg);
}

.pagination-button.next > img {
  transform: rotateZ(-90deg);
}
