.footer {
  background-color: #2E3333;
  padding: 40px 0px 0px 0px;
  grid-template-columns: repeat(2, 1fr);
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  /* grid-template-rows: repeat(2, 1fr); */
  /* row-gap: 40px; */
  overflow: hidden;
}

.menu-links h4, .links h4 {
  font-size: 14px !important;
}


.socials {
  display: flex;

  align-items: center;
  justify-content: flex-start;
}

.socials>div {
  cursor: pointer;
}


.socials>div:not(:last-child) {
  margin-right: 15px;
}


.content {
  display: flex;
  flex-direction: flex-row;
  flex-wrap: wrap;
  width:68%;
  align-self: center;
  justify-content: space-between;
}

.content > div {
  cursor: pointer;

}

.links>h4 {
  padding: 5px 5px 0px 0px;
  cursor: pointer;
}

.links a {
  text-decoration: none !important;
}

.links p {
  font-family: OpenSansBold;
  font-size: 20px !important;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


@media (max-width:1180px) {
  .links h4 {
    font-size: 13px !important;
  }

  .links p {
    font-size: 18px !important;
  }
}

@media screen and (orientation:portrait) and (max-width: 768px) {

  .content {
    flex-direction: column;
    align-items: center;
    row-gap: 29px;
  }

  .menu-links {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .links h4 {
    margin: 5px;
    font-size: 14px !important;
    text-align: center;
    cursor: pointer;
  }

  .socials {
   margin-top: 30px;
  }

  .links p {
    font-size: 20px !important;
    text-align: center;
    cursor: pointer;
  }
  
  .line {
    height: 10px;
    width: 1px;
    background-color: white;
  }

  .wrap {
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

}


@media screen and (min-width:320px) and (max-width:915px) and (orientation:landscape) {

  .content {
    flex-direction: column;
    align-items: center;
    row-gap: 29px;
  }

  .menu-links {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .links h4 {
    margin: 5px;
    font-size: 16px !important;
    text-align: center;
    cursor: pointer;
  }

  .links p {
    font-size: 20px !important;
    text-align: center;
    cursor: pointer;
  }
  
  .line {
    height: 10px;
    width: 1px;
    background-color: white;
  }

  .wrap {
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}